import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/hgr/ws/website-js/src/templates/infoDocTemplateMdx.js"
import EsaEfreSupport from './../components/EsaEfreSupport'
export const frontmatter = {
  path: "/company",
  date: "2018-10-06",
  title: "Company",
}
export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>



<MDXTag name="p" components={components}>{`Gestalt Systems develops and operates the maritime data and application platform
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.aquaplot.com"}}>{`Aquaplot`}</MDXTag>{`. Using the web app
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.aquaplot.com/explorer"}}>{`Aquaplot Explorer`}</MDXTag>{`, users can perform a myriad of
common tasks such as estimating voyage cost or tracking fleets of vessels via
AIS (Automatic Identification System). Through `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.aquaplot.com/api"}}>{`Aquaplot and third party API`}</MDXTag>{`
services including Vessel Routing, AIS Tracking or Weather Forecasts, innovators get the
right tools, services and data to help them build and grow their solutions.`}</MDXTag>
{/* The maritime space is flush with data and space technology plays a major role in its acquisition. From the Automated Identification System (AIS), which delivers positional and operational data, to weather monitoring and forecasting - the only way to collect this data at high sea reliably is by using satellites. 
Many businesses and agencies across a range of sectors, e.g. oil and gas or fishing, need this data for operational and strategic decisions. Oftentimes routing technology is required to act as the connective fiber which combines many of these data points into actionable information. On an individual asset level, this could be the calculation of an estimated arrival time (ETA). Computing and aggregating these for thousands or tens of thousands of vessels gives insight into the availability of vessels in different markets which informs purchasing decisions. However, most available routing tools lack flexibility and/or performance to get the most out of this wealth of data. */}

{/* Gestalt Systems is developing routing algorithms based on nature-inspired artificial intelligence and several other optimization techniques. The system - a patent application pending (EPO intention to grant received) - called Evoswarm is designed from first principles to deliver unprecedented performance and flexibility to drive down the cost of information generation whilst allowing users to customize calculations to fit their specific use case. For example, a particular vessel will only be able to pass through some channels on a ballast leg (when no cargo onboard). Ultimately, we want to be able to forecast the movement of all commercial vessels globally whilst also taking into account dynamic effects such as weather conditions and traffic densities. In other words, Evoswarm will be able to use all available data sources to give a more accurate picture for decision making. */}

<MDXTag name="h4" components={components}>{`EFRE Support Notice`}</MDXTag>
<MDXTag name="p" components={components}>{`Gestalt Systems GmbH is an innovative startup and joined the Business Incubation Center Program of
the European Space Agency in Darmstadt in September 2017. The company develops smart software solutions
for maritime utilizing space technology such as earth observational data and is receiving financial support
from the European Union (EFRE).`}</MDXTag>
<EsaEfreSupport /></MDXTag>

export const _frontmatter = {};

  